<template lang="html">

    <section class="paginator container">
        <div class="container-buttons">
            <button class="prev-page" title="Ir a la página anterior" @click="goPrev" :class="[this.pageActual > 1 ? 'active-class' : 'disabled-class']"></button>
            <template v-for="(item, key) in totalItems" :key="key">
                <button @click="gotopage(item)" :class="[pageActual == item ? 'page-item active' : 'page-item']" :title="'Ir a la página ' + item">
                    {{ item }}
                </button>
            </template>
            <button title="Ir a la página siguiente" @click="goNext" :class="[this.pageActual < this.totalItems ? 'active-class next-page' : 'disabled-class next-page']"></button>
        </div>
    </section>

</template>

<script lang="js">
    import utils from "@/utils/utils.js";
    export default {
        name: 'paginator',
        props: ['pageActual', 'totalItems'],
        mounted() {

        },
        data() {
            return {

            }
        },
        methods: {
            gotopage(item) {
                this.$emit('clicked', item);
                utils.LoaderScrollToTop();
            },
            goNext() {
                this.$emit('clicked', this.pageActual + 1);
                utils.LoaderScrollToTop();
            },
            goPrev() {
                this.$emit('clicked', this.pageActual - 1);
                utils.LoaderScrollToTop();
            }
        },
        computed: {

        }
    }


</script>

<style scoped lang="scss">
    .paginator {}
</style>