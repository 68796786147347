<template lang="html">

  <section class="support support-getting-started about-us">
    <section class="slider-full">
      <div class="slide ">
        <div class="d-flex jcsb container-slide container aic">
          <div class="div-text ">
            <Waypoint>
              <h1 class="title-big -whiteC">
                {{ $t('how_to_videos.intro') }}
              </h1>
            </Waypoint>
            <Waypoint>
              <p class="-whiteC">
                {{ $t('how_to_videos.texto1') }}
              </p>

            </Waypoint>
          </div>
          <div class="div-image">
            <Vue3Lottie :animationData="headSupportIntern" height="auto" width="100%" :loop="1" />
          </div>
        </div>

      </div>
    </section>
    <section class="container container-search">
      <div class="div-search">
        <input type="text" placeholder="Search" v-model="textSearch">
        <button class="btn-search" @click="searchText"></button>
      </div>
    </section>
    <section class="content-info container how-video-container">
      <div class="menu-filters" v-if="Object.keys(store.videos.categories).length != 0">
        <p class="title" @click="toggleShowCategories"
          :class="[dropDownCategories == true ? 'title active-dropdrow' : 'title']">{{ $t('how_to_videos.categories') }}
        </p>
        <div v-for="(categorieVideo, key ) in store.videos.categories" :key="key"
          :class="[dropDownCategories == true ? 'div-check active-dropdrow' : 'div-check']">
          <input type="checkbox" :id="categorieVideo.dataId" :value="categorieVideo.dataId" v-model="checkedCategories">
          <label :for="categorieVideo.dataId">{{ categorieVideo.name }}</label>
        </div>
      </div>

      <div class="container-results">
        <div class="grid-2" v-if="store.getVideos(this.page)">
          <div class="grid" v-for="(video, key ) in store.getVideos(this.page)" :key="key">

            <p class="title-medium">{{ video.name }}</p>
            <p class="date">{{ currentDateTime(video.publish_date) }}</p>
            <div class="container-video column-center">
              <iframe v-if="video.url" width="100%" height="315" :src="`https://www.youtube.com/embed/` + YouTubeGetID(video.url) + ``" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p class="-blueC">{{ video.header }}</p>

          </div>

        </div>
        <template v-else>
          <p class="title-medium text-center title-no-result">{{ $t('how_to_videos.not_results_found') }}</p>
        </template>
        <paginator v-if="store.getVideos(this.page)" :pageActual="page" :totalItems="VideoMaxItems"
          @clicked="changePages"></paginator>
      </div>
    </section>
    <section class="other-support-container">
      <div class="container">
        <h2 class="title-medium">{{ $t('support.other') }}</h2>
        <div class="container-link">
          <router-link :to="{ 'name': 'support-started' }" class="item">
            <img src="/img/icons/getting-stated-black.svg" alt=" Getting Started">
            <p class="title-semi-medium -blueDarkC">
              {{ $t('support.getting_started') }}
            </p>
            <p class="description">
              {{ $t('support.getting_started_text') }}
            </p>
          </router-link>
          <router-link :to="{ 'name': 'support-release' }" class="item">
            <img src="/img/icons/relased-notes-black.svg" alt="How to Videos">
            <p class="title-semi-medium -blueDarkC">
              {{ $t('support.release_notes') }}
            </p>
            <p class="description">
              {{ $t('support.release_notes_text') }}

            </p>
          </router-link>
        </div>
      </div>
    </section>
    <section class="banner-footer">
      <div class="container">
        <p class="title-medium -blueDarkC">{{ $t('support.intro5') }}</p>
        <router-link :to="{ name: 'contact' }" class="btn-blue">
          {{ $t('support.button') }}
        </router-link>
      </div>
    </section>
  </section>

</template>

<script lang="js">
import moment from 'moment'
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import headSupportIntern from '@/lotties/head-support-intern.json'
import paginator from '@/components/paginator.vue'
import { contentStore } from '@/stores/contents'

const store = contentStore();
export default {
  name: 'support-how-to-videos',
  props: [],
  components: {
    Vue3Lottie,
    paginator,
  },
  setup() {
    return {
      store,
      getVideos: store.getVideos,
      getVideosPage: store.getVideosPage,
      setPageVideos: store.setPageVideos,
    }
  },
  mounted() {
    store.loadVideos({
      page: this.page,
    })
    store.loadVideosCategories()
  },
  data() {
    return {
      checkedCategories: [],
      textSearch: null,
      headSupportIntern,
      dropDownCategories: false,
    }
  },
  methods: {
    currentDateTime(date) {
      return moment(date).locale('en').format('LL')
    },
    YouTubeGetID(url) {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_]/i)[0] : url[0];
    },
    toggleShowCategories: function () {
      this.dropDownCategories = !this.dropDownCategories;

    },
    changePages(item) {
      this.setPageVideos(item)
      store.loadVideos(
        {
          page: this.page,
        }
      )
    },
    searchText() {
      store.loadVideos(
        {
          page: this.page,
          text: this.textSearch
        }
      )
      // this.removeSearch = true

    },
  },
  computed: {
    page() {
      return store.getVideosPage
    },
    VideoMaxItems() {
      return store.getPagesVideo();
    },
  },
  watch: {
    'textSearch'() {
      if (this.textSearch == '') {
        store.loadVideos(
          {
            page: this.page,
            text: null
          }
        )
      }
    },
    checkedCategories() {
      store.loadVideos(
        {
          filter: this.checkedCategories
        }
      )
    },
    "store.lang": function(newLang){
      this.changePages(1);
      store.loadVideosCategories();
    }
  }
}


</script>

<style scoped lang="scss">
  .body-app {
  padding-top: 47px;
}
@media screen and (max-width:600px){
  .support.about-us .slider-full .slide {
        background: #1E2F3F;
        position: relative;
        height: 520px;
    }
}
</style>